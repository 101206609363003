<template>
<div class="hero min-h-screen bg-base-200">
  <div class="hero-content text-center">
    <div class="max-w-2xl">
      <h1 class="text-5xl font-bold">Enhance Your Business With<br /><span class="text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">Concept Collective</span></h1>
      <p class="py-10">At Concept Collective, we're dedicated to empowering businesses across WA with top-tier IT solutions. Our mission is simple: to equip you with the resources you need to excel. Partner with us and experience the difference.</p>
      <p>We're currently in the process of enhancing our website. For inquiries, please reach out to us via the email below</p>
      <div class="badge badge-primary">contact@conceptcollective.com.au</div>
    </div>
  </div>
</div>
</template>
<script setup lang="ts">
        useHead({
        htmlAttrs: {
          lang: 'en'
        },
        title: 'Concept Collective',
        meta: [
            { name: 'title', content: 'Concept Collective | Trusted IT Solutions for Businesses in Western Australia' },
            { name: 'description', content: 'Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more.' },
            { name: 'keywords', content: 'IT Solutions Western Australia, Managed IT Services, Cybersecurity, Cloud Solutions, Network Optimization, Business Software, Tech Support, IT Consulting' },
            { name: 'geo.region', content: 'AU-WA' },
            { name: 'geo.placename', content: 'Perth' },
            { name: 'geo.position', content: '-31.9505;115.8605' },
            { name: 'ICBM', content: '-31.9505, 115.8605' },
            { name: 'robots', content: 'index, follow' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
            { name: 'canonical', content: 'https://conceptcollective.com.au/' },
            { name: 'hreflang', content: 'en-au' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'og:title', content: 'Trusted IT Solutions for Businesses in Western Australia | Your IT Company' },
            { name: 'og:description', content: 'Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more.' },
            { name: 'og:type', content: 'website' },
            { name: 'og:url', content: 'https://conceptcollective.com.au/' },
            { name: 'og:image', content: 'https://conceptcollective.com.au/assets/img/gradient.png' },
            { name: 'schema', content: 'https://schema.org/' },
            { name: 'alt', content: 'IT Solutions in Perth' }
        ],
        bodyAttrs: {
            class: 'test'
        },
        script: [ { innerHTML: 'console.log(\'Hello world\')' } ]
        })
        useSeoMeta({
        title: 'Trusted IT Solutions for Businesses in Western Australia | Your IT Company',
        ogTitle: 'Trusted IT Solutions for Businesses in Western Australia | Your IT Company',
        description: 'Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more.',
        ogDescription: 'Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more.',
        ogImage: 'https://conceptcollective.com.au/images/og-image.jpg',
        twitterCard: 'summary_large_image',
        });
</script>