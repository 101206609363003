<script setup lang="ts">
        useHead({
        title: 'Concept Collective',
        meta: [
            { name: 'title', content: 'Trusted IT Solutions for Businesses in Western Australia | Your IT Company' },
            { name: 'description', content: 'Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more.' },
            { name: 'keywords', content: 'IT Solutions Western Australia, Managed IT Services, Cybersecurity, Cloud Solutions, Network Optimization, Business Software, Tech Support, IT Consulting' },
            { name: 'geo.region', content: 'AU-WA' },
            { name: 'geo.placename', content: 'Perth' },
            { name: 'geo.position', content: '-31.9505;115.8605' },
            { name: 'ICBM', content: '-31.9505, 115.8605' },
            { name: 'robots', content: 'index, follow' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
            { name: 'canonical', content: 'https://conceptcollective.com.au/' },
            { name: 'hreflang', content: 'en-au' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'og:title', content: 'Trusted IT Solutions for Businesses in Western Australia | Your IT Company' },
            { name: 'og:description', content: 'Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more.' },
            { name: 'og:type', content: 'website' },
            { name: 'og:url', content: 'https://conceptcollective.com.au/' },
            { name: 'og:image', content: 'https://conceptcollective.com.au/images/og-image.jpg' },
            { name: 'schema', content: 'https://schema.org/' },
            { name: 'alt', content: 'IT Solutions in Perth' }
        ],
        bodyAttrs: {
            class: 'test'
        },
        script: [ { innerHTML: 'console.log(\'Hello world\')' } ]
        })
        useSeoMeta({
        title: 'Trusted IT Solutions for Businesses in Western Australia | Your IT Company',
        ogTitle: 'Trusted IT Solutions for Businesses in Western Australia | Your IT Company',
        description: 'Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more.',
        ogDescription: 'Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more.',
        ogImage: 'https://conceptcollective.com.au/images/og-image.jpg',
        twitterCard: 'summary_large_image',
        });
</script>

<template>
    <body>
        <MainHero />
    </body>
</template>
